import React, {useEffect, useState} from 'react';
import './_style.css';
import {getEmail} from "../../api";
import Header from "../../components/header/index.js";
import NavBar from "../../components/navbar/index.js";
import axios from "axios";
import {Button, Popover, Select, Input, Checkbox, Col, Row} from 'antd';


function ConfigEditFields({email, activeMenu}) {
    const [userEmail, setUserEmail] = useState('');
    const [status, setStatus] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedField, setSelectedField] = useState('');
    const [clientNames, setClientNames] = useState([]);
    const [fieldNames, setFieldNames] = useState([]);
    const [inputFieldName, setInputFieldName] = useState('');
    const [textareaPrompt, setTextareaPrompt] = useState('');
    const [clientSpecific, setClientSpecific] = useState(false);
    const [includeSource, setIncludeSource] = useState('true');
    const [useFocal, setUseFocal] = useState('false');
    const [confirmPopoverVisible, setConfirmPopoverVisible] = useState(false);
    const [deletePopoverVisible, setDeletePopoverVisible] = useState(false);
    const [savePopoverVisible, setSavePopoverVisible] = useState(false);
    const {TextArea} = Input;
    const {Option} = Select;
    const deletePopoverContent = <div>Are you sure you want to delete this field? </div>;
    const savePopoverContent = (
        <div>Saved successfully!</div>
    );
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;


    useEffect(() => {
        const fetchEmail = async () => {
            const email = await getEmail();
            console.log("Fetched email:", email);
            if (email) {
                setUserEmail(email);
                console.log("Set userEmail state:", userEmail);
            }
        };
        fetchEmail();
    }, []);

    useEffect(() => {
        // 2. Fetch the client names from the API within the useEffect hook
        const fetchClientNames = async () => {
            try {
                console.log("Fetching client names...");
                const response = await axios.get(`${apiBaseUrl}/api/organizations/`, {
                    params: {orgtype: "client"}
                });
                if (response.data) {
                    setClientNames(response.data);
                }
            } catch (error) {
                console.error("Error fetching client names:", error);
            }
        };

        fetchClientNames();
    }, []);
    useEffect(() => {
        // 2. Fetch the field names from the API within the useEffect hook
        fetchFieldNames();
    }, []);

    const fetchFieldNames = async () => {
        try {
            console.log("Fetching field names...");
            const response = await axios.get(`${apiBaseUrl}/api/interview_fields/`);
            if (response.data) {
                setFieldNames(response.data);
            }
        } catch (error) {
            console.error("Error fetching field names:", error);
        }
    };

    const handleDelete = () => {
        console.log("Handle delete logic here.");
        // After handling deletion, close the popover:
        setTimeout(() => {
            setDeletePopoverVisible(false);
        }, 2000);
    };
    const handleSave = async () => {
        // The data to be sent in the request
        const data = {
            field_name: inputFieldName,
            field_prompt: textareaPrompt,
            client_specific: clientSpecific,
            client: selectedClient,
            include_source: includeSource === 'true'// assuming the client endpoint expects an ID, adjust if needed
        };

        try {
            if (selectedField) {
                data.field_id = selectedField;
                // If a field is selected, make a PUT request
                await axios.put(`${apiBaseUrl}/api/interview_fields/`, data);
            } else {
                // If no field is selected, make a POST request
                await axios.post(`${apiBaseUrl}/api/interview_fields/`, data);
            }
            // Handle successful request (like updating the UI or showing a success message)
            // If the request is successful, show the popover
            fetchFieldNames();
            setSavePopoverVisible(true);
            // Close the popover after 2 seconds
            setTimeout(() => {
                setSavePopoverVisible(false);
            }, 2000);

        } catch (error) {
            console.error("Error saving field data:", error);
            // Handle error (like showing an error message to the user)
        }
    };


    return (
        <>
            <div>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <Header/>
                <NavBar email={userEmail} activeMenu="input"/>
                <div>
                    <h2>Edit Fields</h2>
                    <Row>
                        <Col span={6}></Col>
                        <Col span={18}>
                        <div id="wrapper_body_components">
                            <div id="page_config_submission">
                                <Select id="select_field"
                                        value={selectedField}
                                        style={{width: '100%', marginRight: '10px'}}
                                        dropdownStyle={{ textAlign: 'left' }}
                                        onChange={(value) => {
                                            const selectedFieldId = value;
                                            const selectedFieldData = fieldNames.find(field => field.id === Number(selectedFieldId));
                                            setInputFieldName(selectedFieldData ? selectedFieldData.field_name : '');
                                            setTextareaPrompt(selectedFieldData ? selectedFieldData.field_prompt : '');
                                            setIncludeSource(selectedFieldData ? String(selectedFieldData.include_source) : '');
                                            setSelectedField(selectedFieldId);
                                            if (selectedFieldData) {
                                                setClientSpecific(!selectedFieldData.is_generic); // If is_generic is false, then it's client-specific
                                                setSelectedClient(selectedFieldData.org_id || '');
                                            }
                                        }}
                                >
                                    <Option value="">Clear Selection</Option> {/* <-- This line was added */}
                                    <Option value="" disabled hidden>Select Field Name</Option>
                                    {
                                        fieldNames.map(field => (
                                            <Option key={field.id} value={field.id}>
                                                {field.field_name}
                                            </Option>
                                        ))
                                    }
                                </Select>


                            </div>
                            <div id="page_config_details">

                                <TextArea
                                    type="text"
                                    placeholder="Field Name"
                                    id="input_field_name"
                                    value={inputFieldName}  // Bind the value
                                    onChange={e => setInputFieldName(e.target.value)}

                                />

                                <TextArea
                                    placeholder="Prompt"
                                    id="textarea_prompt"
                                    style={{height: '120px'}}
                                    value={textareaPrompt}  // Bind the value
                                    onChange={e => setTextareaPrompt(e.target.value)}  // Update state variable when value changes
                                />

                                <label htmlFor="include_source">Include Source Quotes:</label>
                                <Select id="include_source"

                                        size="medium"
                                        style={{width: '120px', marginRight: '10px'}}
                                        value={includeSource}
                                        onChange={value => setIncludeSource(value)}
                                >
                                    <Option value="true">True</Option>
                                    <Option value="false">False</Option>
                                </Select>
<label htmlFor="include_source">Client Name:</label>

                                <Select id="config_edit_select_client"

                                        value={selectedClient} // Bind the selected value to the state variable
                                        onChange={(value) => setSelectedClient(value)} // Update the state variable on selection change
                                >
                                    <option value="" disabled hidden>Select Client Name</option>
                                    {
                                        clientNames.map(client => (
                                            <option key={client.id} value={client.id}>
                                                {client.name}
                                            </option>
                                        ))
                                    }
                                </Select>
<Row>
                                <label style={{marginLeft: '36px'}}  htmlFor="checkbox_input">Client Specific</label>
                                    <input
                                        type="checkbox"
                                        id="checkbox_input"
                                        checked={clientSpecific}

                                        onChange={e => setClientSpecific(e.target.checked)}
                                    />


</Row>
                                <br/><br/>
                                <div id="config_edit_buttons_container">


                                    <Popover
                                        content={savePopoverContent}
                                        visible={savePopoverVisible}
                                        trigger="click"
                                        placement="topLeft"
                                        onVisibleChange={(visible) => {
                                            // If you still want to allow manual closing of the popover, you can use this
                                            if (!visible) {
                                                setSavePopoverVisible(false);
                                            }
                                        }}
                                    >
                                        <Button type="primary" id="button_config_edit_save" onClick={handleSave}>
                                            Save
                                        </Button>
                                    </Popover>


                                </div>
                            </div>
                        </div>
                        <div id="wrapper_labels">
                            <p id="label_Submission_Form">Submission Form</p>

                            <p id="label_Field_Details">Field Details</p>
                        </div>
                            </Col>
                    </Row>
                </div>

            </div>
        </>
    );
}

export default ConfigEditFields;

import React, {useState, useEffect} from 'react';
import './_style.css';
import Header from "../../components/header/index.js";
import NavBar from "../../components/navbar/index.js";
import {AutoComplete, Row, Col, Table, Button, Select, Popover, message, Checkbox} from 'antd';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function ConfigAssignProspects({email, activeMenu}) {
    const [children, setChildren] = useState([]);
    const [searchText, setSearchText] = useState('');
    const [selectedProspect, setSelectedProspect] = useState('');
    const [prospectNames, setProspectNames] = useState([]);

    // Assume startDate and endDate are states that you would manage for the date range
    const [clientId] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [selectedChildren, setSelectedChildren] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [clientNames, setClientNames] = useState([]);
    const [visible, setVisible] = useState(false);

    const handleVisibleChange = visible => {
        setVisible(visible);
    };

    const rowSelection = {
        selectedRowKeys: selectedChildren,
        onChange: (selectedRowKeys) => {
            setSelectedChildren(selectedRowKeys);
        },
    };


    const handleRemove = async () => {
        // assuming selectedRowKeys is an array of prospect IDs you want to remove
        try {
            // Collect all the remove promises
            const removePromises = selectedChildren.map(async (childId) => {
                const url = `${apiBaseUrl}/api/organizations_parents/${selectedClient}/children/${childId}`;
                await axios.delete(url); // use axios.delete for removal, and assuming the URL is correct
            });

            // Await all removal operations to complete
            await Promise.all(removePromises);

            // After all deletions are successful, fetch the updated list of children
            fetchChildren();
            message.success('Selected prospects have been removed successfully.');
        } catch (error) {
            console.error("Error removing prospects:", error);
            message.error('An error occurred while removing selected prospects.');
        }
    };

    const handleAdd = async () => {
        try {
            const url = `${apiBaseUrl}/api/organizations_parents/${selectedClient}/children/${selectedProspect.id}`;
            const response = await axios.post(url);

            if (response.data) {
                fetchChildren();
            }
        } catch (error) {
            console.error("Error fetching run fields:", error);
        }
        // call API or update state to add selected
    }
    const handleSelect = (value, option) => {
        setSelectedProspect({
            value: option.value, // The label is the display value (participant name)
            id: option.key, // The participant's ID from your new payload
        });
    }


    const columns = [


        {
            title: 'Prospect Name',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Prospect ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Prospect Type',
            dataIndex: 'subtype',
            key: 'subtype',
        },

        {
            title: 'Created Time',
            dataIndex: 'createdAt',
            key: 'createdAt',
        }
    ];

    useEffect(() => {
        if (selectedClient) {
            fetchChildren();
        }
    }, [selectedClient]);


const fetchChildren = async () => {
    try {
        const response = await axios.get(`${apiBaseUrl}/api/organizations/children/${selectedClient}`);

        const childrenOptions = response.data.map(item => {
            // Date formatting must happen within the map function, for each item
            let date = new Date(item.created_at);
            const formattedDate = date.toLocaleDateString('en-US', {
                month: '2-digit',
                day: '2-digit',
                year: 'numeric'
            });

            const formattedTime = date.toLocaleTimeString('en-US', {
                hour: '2-digit',
                minute: '2-digit'
            });

            return {
                key: item.id,
                value: item.name,
                label: item.name,
                subtype: item.subtype,
                id: item.id,
                orgId: item.parent_id,
                orgName: item.parent_name,
                createdAt: `${formattedDate} ${formattedTime}`
            };
        });

        setChildren(childrenOptions);
    } catch (error) {
        console.error('Failed to fetch children:', error);
    }
};


    useEffect(() => {
        // 2. Fetch the client names from the API within the useEffect hook
        const fetchClientNames = async () => {
            try {
                console.log("Fetching client names...");
                const response = await axios.get(`${apiBaseUrl}/api/organizations/`, {
                    params: {orgtype: "client"}
                });
                if (response.data) {
                    setClientNames(response.data);
                }
            } catch (error) {
                console.error("Error fetching client names:", error);
            }
        };
        fetchClientNames();
    }, []);

    useEffect(() => {
        // 2. Fetch the client names from the API within the useEffect hook
        const fetchProspectNames = async () => {
            try {
                console.log("Fetching prospect names...");
                const response = await axios.get(`${apiBaseUrl}/api/organizations/`, {
                    params: {orgtype: "prospect"}
                });
                if (response.data) {
                    const formattedProspects = response.data.map(org => ({
                        value: org.name,
                        key: org.id
                    }));
                    setProspectNames(formattedProspects);
                }
            } catch (error) {
                console.error("Error fetching prospect names:", error);
            }
        };

        fetchProspectNames();
    }, []);


    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <Header/>
            <NavBar email={email} activeMenu={activeMenu}/>

            <Row>
                <Col span={7}></Col>
                <Col span={10} style={{alignItems: "left", marginTop: "20px", padding: "10px"}}>
                    {/* Here is the AutoComplete component */}
                    <h2>Assign Prospects to Client Organization</h2>

                    <label style={{color: "white"}}>Select Client Org: </label>
                    <Select
                        style={{marginBottom: '10px', marginTop: '10px', width: "40%"}}
                        value={selectedClient}
                        onChange={(value) => setSelectedClient(value)}
                    >
                        <option value="" disabled hidden>Select Client</option>
                        {
                            clientNames.map(client => (
                                <option key={client.id} value={client.id}>
                                    {client.name}
                                </option>
                            ))
                        }
                    </Select>
                    {selectedClient && (<h3>View & Remove Prospect Affiliations</h3>)}
                    {selectedClient && (

                        <Table
                            rowSelection={rowSelection}
                            className="round-bottom-table"
                            columns={columns}
                            dataSource={children}
                            pagination={false}
                            scroll={{
        y: 600,
      }}

                        />


                    )

                    }
                    {selectedClient && (
                        <Button
                            className="custom-padding"
                            type="primary"
                            danger
                            onClick={handleRemove}
                        >
                            Remove Selected
                        </Button>
                    )}
                    {selectedClient && (<h3>Add Prospect Associations</h3>)}
                    {selectedClient && (
                        <AutoComplete
                            style={{width: "100%"}}
                            value={searchText}
                            onChange={setSearchText}
                            placeholder="Search Prospect Organizations"
                            options={prospectNames}
                            filterOption={(inputValue, option) =>
                                option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                            onSelect={handleSelect}
                        />)}
                    {selectedClient > 0 && (
                        <Button
                            className="custom-padding"
                            type="primary"

                            onClick={handleAdd}
                        >
                            Add Selected
                        </Button>
                    )}
                </Col>
                <Col span={7}></Col>
            </Row>
        </div>
    );
}

export default ConfigAssignProspects;

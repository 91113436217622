import './style.css'
import Routes from "./routes"
import React, {useEffect, useState} from 'react';
import {BrowserRouter, useNavigate} from 'react-router-dom';
import {getEmail} from "./api";


function App() {
  const navigate = useNavigate();
  useEffect(() => {
      // Prevent running on specific subdomains and paths
      const currentHost = window.location.host;
      const currentPath = window.location.pathname;
      // Check if the current host includes the subdomain and the path matches the oauth path
      //if (currentHost.includes('main.pastsighttransc-review.genaipartners.coherencesites.com') && currentPath.startsWith('/oauth/')) {
          // Do not proceed
          //return;
      //}
       const fetchEmail = async () => {
          const email = await getEmail();
          console.log("Fetched email:", email);
          //if (!email) {
              //navigate('/');  // Redirect to the base route if no email
              //navigate('/oauth/google'); // Temporarily enable navigation to proceed normally
          //}
           //... other logic
      };
      fetchEmail();
  }, []);
  return (
      <div className="App">
          <Routes/>
      </div>
  );
}
export default App;
import React, {useState, useEffect} from 'react';
import './_style.css';
import Header from "../../components/header/index.js";
import NavBar from "../../components/navbar/index.js";
import {AutoComplete, Row, Col, Table, Button, Select, Popover, message} from 'antd';
import axios from 'axios';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

function ConfigDeleteInterviews({email, activeMenu}) {
    const [participants, setParticipants] = useState([]);
    const [searchText, setSearchText] = useState('');

    // Assume startDate and endDate are states that you would manage for the date range
    const [clientId] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [clientNames, setClientNames] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedParticipant, setSelectedParticipant] = useState(null);
    const [selectedProspect, setSelectedProspect] = useState('');
    const [prospectNames, setProspectNames] = useState([]);
    const [visible, setVisible] = useState(false);

    const handleVisibleChange = visible => {
        setVisible(visible);
    };
    const columns = [
        {
            title: 'Interviewee Name',
            dataIndex: 'value',
            key: 'value',
        },
        {
            title: 'Participant ID',
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Interview ID',
            dataIndex: 'interviewId',
            key: 'interviewId',
        },
        {
            title: 'Organization ID',
            dataIndex: 'orgId', // This is the organization ID
            key: 'orgId',
        },
        {
            title: 'Organization Name',
            dataIndex: 'orgName', // This is the organization name
            key: 'orgName',
        },
        {
            title: 'Interview Created Time',
            dataIndex: 'createdAt', // This now reflects the created_at time
            key: 'createdAt',
        },
    ];
    useEffect(() => {
        // 2. Fetch the client names from the API within the useEffect hook
        const fetchClientNames = async () => {
            try {
                console.log("Fetching client names...");
                const response = await axios.get(`${apiBaseUrl}/api/organizations/`, {
                    params: {orgtype: "client"}
                });
                if (response.data) {
                    setClientNames(response.data);
                }
            } catch (error) {
                console.error("Error fetching client names:", error);
            }
        };

        fetchClientNames();
    }, []);
    useEffect(() => {
        if (selectedClient) {
            fetchProspectNames();
        }
    }, [selectedClient]);
    useEffect(() => {
        if (selectedProspect) {
            setSearchText('');
            setSelectedParticipant('');
        }
    }, [selectedProspect]);
    useEffect(() => {
        // Define the function to fetch data
        const fetchParticipants = async () => {
            try {
                const response = await axios.get(`${apiBaseUrl}/api/participants/`, {
                    params: {org_id: selectedProspect, expanded: true}
                });

                const participantOptions = response.data.map(item => {
                    return {
                        value: item.name, // This is displayed and selected in the dropdown
                        label: item.name, // The label here is what you see in the dropdown
                        id: item.participant_id, // Keep the participant's original ID
                        interviewId: item.interview_id, // The interview ID from the payload
                        orgId: item.org_id, // The organization ID
                        orgName: item.org_name, // The organization name
                        createdAt: item.created_at // The created_at time
                    };
                });

                setParticipants(participantOptions);
            } catch (error) {
                console.error('Failed to fetch participants:', error);
            }
        };


        // Call the function
        fetchParticipants();
    }, [selectedProspect]); // This will re-fetch when startDate or endDate change
    const fetchProspectNames = async () => {
        setSelectedProspect('');
        setSearchText('');
        setSelectedParticipant('');
        try {
            console.log("Fetching client names...");
            const response = await axios.get(`${apiBaseUrl}/api/organizations/children/${selectedClient}`);
            if (response.data) {
                setProspectNames(response.data);
            }
        } catch (error) {
            console.error("Error fetching prospect names:", error);
        }
    };
    const handleSelect = (value, option) => {
        const date = new Date(option.createdAt);

        // Format date and time
        const formattedDate = date.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });

        const formattedTime = date.toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit'
        });
        // When a participant is selected from the dropdown, update the state
        setSelectedParticipant({
            value: option.label, // The label is the display value (participant name)
            id: option.id, // The participant's ID from your new payload
            interviewId: option.interviewId, // The interview ID from your new payload
            createdAt: `${formattedDate} ${formattedTime} `,
            orgId: option.orgId, // The organization ID from your new payload
            orgName: option.orgName, // The organization name from your new payload
        });
    };

    const handleDelete = async () => {
        setVisible(false);
        if (!selectedParticipant) {
            message.error('No participant selected');
            return;
        }

        try {
            const response = await axios.delete(`${apiBaseUrl}/api/participants/${selectedParticipant.id}`);
            const interviewResponse = await axios.delete(`${apiBaseUrl}/api/interviews/${selectedParticipant.interviewId}`);
            if (response.status === 200) {
                message.success('Interview deleted successfully');
                // Remove the deleted participant from the state
                setParticipants(participants.filter(p => p.id !== selectedParticipant.id));
                setSelectedParticipant(null); // Clear the selection
                setSearchText('');
            }
        } catch (error) {
            message.error('Failed to delete Interview');
            console.error('Failed to delete Interview:', error);
        }
    };
    const DeleteConfirmation = () => (
        <div style={{textAlign: 'center'}}> {/* This will center the text and button */}
            <p>Are you sure you want to delete this interview?</p>
            <Button
                type="primary" danger // This applies the Ant Design 'danger' style
                onClick={handleDelete}
                style={{marginTop: '10px'}} // You can adjust the margin as needed
            >
                Confirm Delete
            </Button>
        </div>
    );

    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <Header/>
            <NavBar email={email} activeMenu={activeMenu}/>

            <Row>
                <Col span={7}></Col>
                <Col span={10} style={{alignItems: "left", marginTop: "20px", padding: "10px"}}>
                    {/* Here is the AutoComplete component */}
                    <h2>Select Interview to Delete</h2>
                    <br></br>
                    <label style={{color: "white"}}>Select Prospect Org (OPTIONAL): </label>
 <br></br>
                    <Select
                        style={{width: '40%'}}
                        id="form-element_input_page_report_client"
                        value={selectedClient} // Bind the selected value to the state variable
                        onChange={(value) => setSelectedClient(value)} // Update the state variable on selection change
                    >
                        <option value="" disabled hidden>Select Client Name</option>
                        {clientNames.map(client => (
                            <option key={client.id} value={client.id}>
                                {client.name}
                            </option>
                        ))}
                    </Select>
                     <br></br>
                    <Select
                        style={{marginBottom: '10px', marginTop: '10px', width: "40%"}}
                        value={selectedProspect}
                        onChange={(value) => setSelectedProspect(value)}
                    >
                        <option value="" disabled hidden>Select Prospect Org</option>
                        {
                            prospectNames.map(prospect => (
                                <option key={prospect.id} value={prospect.id}>
                                    {prospect.name}
                                </option>
                            ))
                        }
                    </Select>
                    <br></br>
                    <AutoComplete
                        style={{width: "40%"}}
                        value={searchText}
                        onChange={setSearchText}
                        placeholder="Search participants"
                        options={participants}
                        filterOption={(inputValue, option) =>
                            option.value.toUpperCase().indexOf(inputValue.toUpperCase()) !== -1}
                        onSelect={handleSelect}
                    />
                    {selectedParticipant && (
                        <Table
                            style={{marginBottom: '10px', marginTop: '10px'}}
                            className="round-bottom-table"
                            columns={columns}
                            dataSource={[selectedParticipant]}
                            pagination={false}
                        />

                    )}
                    <br></br>
                    <Popover
                        content={<DeleteConfirmation/>}
                        title="Confirm Deletion"
                        trigger="click"
                        visible={visible}
                        onVisibleChange={handleVisibleChange}
                    >
                        <Button
                            type="primary"
                            style={{marginTop: '10px'}}
                        >
                            Delete
                        </Button>
                    </Popover>
                </Col>
                <Col span={7}></Col>
            </Row>
        </div>
    );
}

export default ConfigDeleteInterviews;

import React, {useEffect, useRef, useState} from 'react';
import './_style.css';
import Header from "../../components/header/index.js";
import NavBar from "../../components/navbar/index.js";
import CRUDTable from "../../components/crud_table/index.js";
import {Input, Select, Button, Popover, Row, Col} from 'antd';

import axios from "axios";


function ConfigClientProspectOrg({email, activeMenu}) {
    const [selectFieldRight, setSelectFieldRight] = useState('');
    const [clients, setClients] = useState([]);
    const [isEditable, setIsEditable] = useState(false);
    const inputRef = useRef(null);
    const [orgName, setOrgName] = useState('');
    const [orgType, setOrgType] = useState('client');
    const [orgSubType, setOrgSubType] = useState(null);
    const {Option} = Select;
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [popoverMessage, setPopoverMessage] = useState('');
    const [clientNames, setClientNames] = useState([]);
    const [selectedClient, setSelectedClient] = useState('');
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    let popoverTimeout = null;

    useEffect(() => {
        if (orgType !== 'prospect') {
            setOrgSubType(null);
            setSelectedClient(null);
        }
    }, [orgType]);
    useEffect(() => {
        // 2. Fetch the client names from the API within the useEffect hook
        const fetchClientNames = async () => {
            try {
                console.log("Fetching client names...");
                const response = await axios.get(`${apiBaseUrl}/api/organizations/`, {
                    params: {orgtype: "client"}
                });
                if (response.data) {
                    setClientNames(response.data);
                }
            } catch (error) {
                console.error("Error fetching client names:", error);
            }
        };

        fetchClientNames();
    }, []);

    const handleAddClick = () => {
        setIsEditable(true);
        inputRef.current.focus();
        setSelectFieldRight('');
    };

    const handleEditClick = () => {
        setIsEditable(true);
        inputRef.current.focus();
    };

    const handleSubmit = () => {
        const orgData = {
            name: orgName,
            orgtype: orgType,
            orgsubtype: orgSubType,
            parentclientid: selectedClient
        };
        if (!orgName || !orgType) {
            setPopoverMessage("Both organization name and type must have values.");
            setPopoverVisible(true);
            setTimeout(() => setPopoverVisible(false), 2000);
            return;  // Exit the function early
        }
        if (orgType === 'prospect' && !orgSubType) {
            setPopoverMessage("Please select an organization sub-type for prospects.");
            setPopoverVisible(true);
            setTimeout(() => setPopoverVisible(false), 2000);
            return;  // Exit the function early
        }
        axios.post(`${apiBaseUrl}/api/organizations/`, orgData)
            .then(response => {
                console.log('Success:', response.data);
                setOrgName(null);  // Clear the input fields
                setOrgType(null);
                setOrgSubType(null);
                setPopoverMessage('Submitted successfully!');
                setPopoverVisible(true);

                // Set a timeout to hide the popover after 2 seconds
                popoverTimeout = setTimeout(() => {
                    setPopoverVisible(false);
                }, 2000);
            })
            .catch(error => {
                console.error('Error:', error);
                setPopoverMessage('Submission failed.');
                setPopoverVisible(true);
                popoverTimeout = setTimeout(() => {
                    setPopoverVisible(false);
                }, 2000);
            });
    }


    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <Header/>
            <NavBar email="userEmail" activeMenu="input"/>

            <Row>
                <Col span={8}></Col>
                <Col span={8} style={{
                    alignItems: "right",
                    marginTop: "20px",
                    padding: "10px",     // Adds some space around the content inside the box
                    border: "1px solid #ccc" // Optional, adds a light gray border around the box
                }}>

                    <h2>Edit Organizations</h2>
                    <label style={{color: "white"}}>Organization Name: </label>
                    <Input
                        value={orgName}
                        onChange={(e) => setOrgName(e.target.value)}
                        placeholder="Enter Organization Name"
                        style={{marginTop: "10px", width: "40%"}}
                    />
                    <br></br>
                    <label style={{color: "white"}}>Organization Type: </label>
                    <Select
                        id="select_org_type"
                        value={orgType}
                        onChange={(value) => setOrgType(value)}
                        style={{width: "40%"}}
                        placeholder="Select Organization Type"
                    >
                        <Option value="client">Client</Option>
                        <Option value="prospect">Prospect</Option>

                    </Select>
                    <br></br>
                    {orgType === 'prospect' && (
                        <>
                            <label style={{color: "white"}}>Organization SubType: </label>
                            <Select
                                id="select_org_subtype"
                                value={orgSubType}
                                onChange={(value) => setOrgSubType(value)}
                                style={{width: "40%"}}
                                placeholder="Select Organization Type"
                            >
                                <Option value="active client">Active Client</Option>
                                <Option value="churned client">Churned Client</Option>
                                <Option value="lost prospect">Lost Prospect</Option>
                            </Select>
                            <br></br>
                            <label style={{color: "white"}}>Parent Client: </label>
                            <Select
                                style={{marginBottom: '10px', marginLeft: '10px', width: '40%'}}
                                value={selectedClient}
                                onChange={(value) => setSelectedClient(value)}
                            >
                                <option value="" disabled hidden>Select Client Name</option>
                                {
                                    clientNames.map(client => (
                                        <option key={client.id} value={client.id}>
                                            {client.name}
                                        </option>
                                    ))
                                }
                            </Select>
                        </>
                    )}
                    <Popover content={popoverMessage} trigger={[]} visible={popoverVisible}>
                        <br></br><br></br>
                        <Button type="primary" id="submit_client" onClick={handleSubmit}>Save</Button>
                    </Popover>

                </Col>
                <Col span={8}></Col>


            </Row>
        </div>
    );
}

export default ConfigClientProspectOrg;

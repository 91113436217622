import React, {useEffect, useState} from 'react';
import './_style.css';
import {getEmail} from "../../api";
import Header from "../../components/header/index.js";
import NavBar from "../../components/navbar/index.js";
import axios from "axios"; // Import the CheckBoxes component
import CheckBoxes from './CheckBoxes';
import {Styles, Button, Popover, Select,Col, Row} from 'antd';

function ConfigSelectFields({email, activeMenu}) {
    const [userEmail, setUserEmail] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [clientNames, setClientNames] = useState([]);
    const [runFieldsGeneric, setRunFieldsGeneric] = useState([]);
    const [runFieldsClient, setRunFieldsClient] = useState([]);
    const [checkboxStates, setCheckboxStates] = useState({});
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [popoverContent, setPopoverContent] = useState('');
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const toTitleCase = (str) => {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    const handleSave = async () => {
        try {
            console.log(checkboxStates);
            const endpoint = `${apiBaseUrl}/api/client_run_config/`;
            const data = {
                clientID: selectedClient,
                checkboxState: checkboxStates,
                mode: 'bulkupdate'
            };
            const response = await axios.post(endpoint, data);
            if (response.status === 200) {
                console.log('Data saved successfully', response.data);
                setPopoverContent("Submitted Successfully");
            } else {
                console.warn('Error saving data', response);
                setPopoverContent("Error Submitting");
            }
        } catch (error) {
            console.error('An error occurred while saving:', error);
            setPopoverContent("Error Submitting");
        }

        setPopoverVisible(true);  // Show the popover immediately after the request

        setTimeout(() => {
            setPopoverVisible(false); // Hide the popover after 2 seconds
        }, 2000);
    };


    useEffect(() => {
        const fetchEmail = async () => {
            const email = await getEmail();
            console.log("Fetched email:", email);
            if (email) {
                setUserEmail(email);
                console.log("Set userEmail state:", userEmail);
            }
        };
        fetchEmail();
    }, []);

    useEffect(() => {
        const fetchClientNames = async () => {
            try {
                console.log("Fetching client names...");
                const response = await axios.get(`${apiBaseUrl}/api/organizations/`, {
                    params: {orgtype: "client"}
                });
                if (response.data) {
                    setClientNames(response.data);
                }
            } catch (error) {
                console.error("Error fetching client names:", error);
            }
        };
        fetchClientNames();
    }, []);
    //
    useEffect(() => {

        const newCheckboxStates = {};  // Initialize a new state object

        runFieldsClient.forEach(field => {
            newCheckboxStates[field.interview_field_id] = field.client_config_id !== null;
        });

        setCheckboxStates(newCheckboxStates);  // Set the new state
    }, [runFieldsClient]);

    useEffect(() => {
        if (selectedClient) {
            const fetchRunFieldsClient = async () => {
                try {
                    const url = `${apiBaseUrl}/api/client_run_settings/${selectedClient}`;
                    const response = await axios.get(url);

                    if (response.data) {
                        setRunFieldsClient(response.data);
                    }
                } catch (error) {
                    console.error("Error fetching run fields:", error);
                }
            };

            fetchRunFieldsClient();
        } else {
            setRunFieldsClient([]);
        }
    }, [selectedClient]);


    // Here, I've defined the width, height, and number of checkboxes
    const width = '600px';
    const height = '11rem';
    const num_checkbox = runFieldsGeneric.length; // Set the number of checkboxes to the length of runFieldsGeneric


    return (
        <div>
            <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
            <Header/>
            <NavBar email={userEmail} activeMenu="input"/>

            <div className="container_input_page">

                <Col span={6}></Col>
                <div className="left-div_input_page" style={{width:"300px"}}>
                    <div className="inner-div-top_input_page">

                        <h2>Submission Form Setup</h2>
                        <Select id="config_edit_select_client_field"
                                className="page_input_select_box"
                                value={selectedClient} // Bind the selected value to the state variable
                                onChange={(value) => setSelectedClient(value)} // Update the state variable on selection change
                        >
                            <option value="" disabled hidden>Select Client Name</option>
                            {
                                clientNames.map(client => (
                                    <option key={client.id} value={client.id}>
                                        {client.name}
                                    </option>
                                ))
                            }
                        </Select>
                        <br></br>
                        <Popover
                            content={popoverContent}
                            visible={popoverVisible}
                            trigger="click"
                            placement="topLeft"
                            onVisibleChange={(visible) => setPopoverVisible(visible)} // To allow closing the Popover
                        >
                            <Button type="primary" id="button_save_config" onClick={handleSave}>Save</Button>
                        </Popover>
                    </div>
                </div>
                <div className="right-div_input_page">
                    <p id="title_universal_fields">Select Fields</p>

                    <div id="wrapper_checkboxes">


                        <CheckBoxes
                            selectedClient={selectedClient}
                            checkboxStates={checkboxStates}
                            setCheckboxStates={setCheckboxStates}
                        />


                    </div>

                </div>

            </div>

        </div>
    );
}

export default ConfigSelectFields;
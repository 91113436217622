import React, {useEffect, useState} from 'react';
import '../../style.css';
import {getEmail} from "../../api";
import Header from "../../components/header/index.js";
import NavBar from "../../components/navbar/index.js";
import axios from "axios";
import {Select, Button, Table, Col, Row} from "antd";
import {DownloadOutlined} from '@ant-design/icons';
import { Tooltip } from 'antd';


function ReportSubmission({email, activeMenu}) {
    const [userEmail, setUserEmail] = useState('');
    const [status, setStatus] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedProspect, setSelectedProspect] = useState('');
    const [selectedInterviewee, setSelectedInterviewee] = useState('');
    const [clientNames, setClientNames] = useState([]);
    const [prospectNames, setProspectNames] = useState([]);
    const [intervieweeNames, setintervieweeNames] = useState([]);
    const [textareaValue, setTextareaValue] = useState('');
    const [tableData, setTableData] = useState([]);
    const [tableColumns, setTableColumns] = useState([]);
    const [showCopyDisplay, setShowCopyDisplay] = useState(false);

    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    function escapeCSV(value) {
        if (Array.isArray(value)) {
            // Convert the array to a JSON string
            value = JSON.stringify(value);
        } else if (value === null || value === undefined) {
            value = '';
        } else {
            value = value.toString();
        }
        // Escape any existing double quotes in the string
        value = value.replace(/"/g, '""');
        // Quote the entire string
        return '"' + value + '"';
    }


    function convertToCSV(tableData, tableColumns) {
        const header = tableColumns.map(column => escapeCSV(column.title)).join(',');
        const rows = tableData.map(row =>
            tableColumns.map(column => escapeCSV(row[column.dataIndex] || "")).join(',')
        );
        return [header, ...rows].join('\n');
    }


    const handleCSVDownload = () => {
        const csvString = convertToCSV(tableData, tableColumns);

        // Create a blob link and click it to initiate download
        const blob = new Blob([csvString], {type: 'text/csv'});
        const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', 'data.csv');
        document.body.appendChild(link);
        link.click();
        link.remove();
    };


    useEffect(() => {
        const fetchEmail = async () => {
            const email = await getEmail();
            console.log("Fetched email:", email);
            if (email) {
                setUserEmail(email);
                console.log("Set userEmail state:", userEmail);
            }
        };
        fetchEmail();
    }, []);
    useEffect(() => {
        // 2. Fetch the client names from the API within the useEffect hook
        const fetchClientNames = async () => {
            try {
                console.log("Fetching client names...");
                const response = await axios.get(`${apiBaseUrl}/api/organizations/`, {
                    params: {orgtype: "client"}
                });
                if (response.data) {
                    setClientNames(response.data);
                }
            } catch (error) {
                console.error("Error fetching client names:", error);
            }
        };

        fetchClientNames();
    }, []);
    useEffect(() => {
        if (selectedClient) {
            fetchProspectNames();
        }
    }, [selectedClient]);
    const fetchProspectNames = async () => {
        setSelectedProspect('');
        try {
            console.log("Fetching client names...");
            const response = await axios.get(`${apiBaseUrl}/api/organizations/children/${selectedClient}`);
            if (response.data) {
                setProspectNames(response.data);
            }
        } catch (error) {
            console.error("Error fetching prospect names:", error);
        }
    };
    useEffect(() => {
        const fetchIntervieweeNames = async () => {
            try {
                console.log("Fetching participant names...");
                const response = await axios.get(`${apiBaseUrl}/api/participants/`, {
                    params: {org_id: selectedProspect}
                });
                if (response.data) {
                    setintervieweeNames(response.data);
                }
            } catch (error) {
                console.error("Error fetching interviewee names:", error);
            }
        };

        // If selectedProspect has a truthy value, then fetch interviewee names.
        if (selectedProspect) {
            fetchIntervieweeNames();
        }
    }, [selectedProspect]);

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Construct the payload
        const payload = {
            clientId: selectedClient,
            prospectId: selectedProspect,
            intervieweeId: selectedInterviewee,
            response_format: "text"
        };

        try {
            const response = await axios.post(`${apiBaseUrl}/api/submission_report/`, payload);

            if (response.data) {
                // Handle the response accordingly
                console.log("Data submitted successfully:", response.data);
                setStatus('Form submitted successfully!');

                // Set the response data to the textarea
                setTextareaValue(response.data); // Assuming response is an object
            }
        } catch (error) {
            console.error("Error submitting the data:", error);
            setStatus('Error submitting the form!');
        }
    };
const handleCopyToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setShowCopyDisplay(true); // Show the div
    setTimeout(() => setShowCopyDisplay(false), 1000);

};
const handleSubmitTable = async (e) => {
    e.preventDefault();

    // Construct the payload
    const payload = {
        clientId: selectedClient,
        prospectId: selectedProspect,
        intervieweeId: selectedInterviewee,
        response_format: "table"
    };

    try {
        const response = await axios.post(`${apiBaseUrl}/api/submission_report/`, payload);

        if (response.data && response.data.error) {
            setTableData([]);
            throw new Error(response.data.error);
        }

        if (response.data && response.data.data && response.data.data.length) {
            const fixedWidth = 350;  // or any desired width

            const updatedColumns = response.data.headers.map(column => ({
                ...column,
                width: fixedWidth,
render: (text) => {
    const displayText = text && text.length > 400 ? `${text.substring(0, 400)}...` : text;
    return (
        <Tooltip
         overlayStyle={{maxWidth: '600px'}}
            title={<span>{text}</span>}>
            <div className="truncate-text"
            onClick={() => handleCopyToClipboard(text)}>
                {displayText}
            </div>
        </Tooltip>
    );
}

            }));

            setTableColumns(updatedColumns);
            setTableData(response.data.data);
        } else {
            setTableData([]);
            setStatus('No Matching Interviews');
        }
    } catch (error) {
        console.error("Error submitting the data:", error);
        setStatus('Error : ' + error);
    }
};


    return (
        <>
            <div>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <Header/>
                <NavBar email={userEmail} activeMenu="input"/>
                <Row align={'center'}><h2>Report - Submission</h2></Row>
                <Col span={1}></Col>
                <Row>
                    <Col span={1}></Col>
                    <Col span={3} style={{alignItems: "left", marginTop: "25px", padding: "10px"}}>
                        <form id="page_report_submission">
<h3>Choose Client</h3>

                            <Select
                                style={{ width: '100%' }}
                                id="form-element_input_page_report_client"
                                value={selectedClient} // Bind the selected value to the state variable
                                onChange={(value) => setSelectedClient(value)} // Update the state variable on selection change
                            >
                                <option value="" disabled hidden>Select Client Name</option>
                                {clientNames.map(client => (
                                    <option key={client.id} value={client.id}>
                                        {client.name}
                                    </option>
                                ))}
                            </Select>
                            <br></br>
                            <Select
                                style={{ width: '100%' }}
                                id="form-element_input_page_report_prospect"
                                value={selectedProspect} // Bind the selected value to the state variable
                                onChange={(value) => setSelectedProspect(value)} // Update the state variable on selection change
                            >
                                <option value="" disabled hidden>Select Prospect Org</option>
                                {prospectNames.map(prospect => (
                                    <option key={prospect.id} value={prospect.id}>
                                        {prospect.name}
                                    </option>
                                ))}
                            </Select>
                            <Select
                                style={{ width: '100%' }}
                                id="form-element_input_page_report_interviewee"
                                value={selectedInterviewee} // Bind the selected value to the state variable
                                onChange={(value) => setSelectedInterviewee(value)} // Update the state variable on selection change
                            >
                                <option value="" disabled hidden>Select Interviewee</option>
                                {intervieweeNames.map(interviewee => (
                                    <option key={interviewee.id} value={interviewee.id}>
                                        {interviewee.name}
                                    </option>
                                ))}
                            </Select>
                            <br></br>
                            <br></br>
                            <Button
                                type="primary"
                                className="form-element_input_page"
                                id="form-element_submit_submission_button"
                                onClick={handleSubmitTable}
                            >
                                Submit
                            </Button>
<br></br>
                            <div id='copy-display'style={{
                                marginTop: '20px',
                                marginBottom: '20px',
                                color: 'White',
                                visibility: showCopyDisplay ? 'visible' : 'hidden'
                            }} > Copied Cell</div>
                        </form>
                    </Col>
                    <Col span={20}>
                        <div className="right-div_input_page">
 <Button icon={<DownloadOutlined/>} className="download-button" onClick={handleCSVDownload} style={{marginBottom:'20px'}}>Download
                                as CSV</Button>

                            <Table className="scrollable-text-table" style={{
                                marginTop: '20px',
                                marginBottom: '20px',
                                verticalAlign: 'top',
                                overflow: 'scroll'
                            }} dataSource={tableData} columns={tableColumns} scroll={{x: 'max-content'}}/>





                        </div>
                    </Col>
                </Row>
            </div>
        </>
    );
}

export default ReportSubmission;

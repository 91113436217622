import React, {useEffect, useState} from 'react';
import '../../style.css';
import {getEmail} from "../../api";
import Header from "../../components/header/index.js";
import NavBar from "../../components/navbar/index.js";
import axios from 'axios'; // Assuming you're using axios, but you can use fetch or any other HTTP request library
import {Button, Popover, Input, DatePicker, Select, Col, Row, Modal, Table} from 'antd';
import {useNavigate} from 'react-router-dom';


function InputPage({email, activeMenu}) {
    const [userEmail, setUserEmail] = useState('');
    const [status, setStatus] = useState('');
    const [clientNames, setClientNames] = useState([]);
    const [modelNames, setModelNames] = useState([]);
    const [prospectNames, setProspectNames] = useState([]);
    const [interviewNames, setInterviewNames] = useState([]);
    const [transcript, setTranscript] = useState('');
    const [keyFocusArea, setKeyFocusArea] = useState('');
    const [interviewee, setInterviewee] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedModel, setSelectedModel] = useState('');
    const [selectedProspect, setSelectedProspect] = useState('');
    const [selectedInterview, setSelectedInterview] = useState('')
    const [interviewDate, setInterviewDate] = useState('');
    const [error, setError] = useState('');
    const [popoverVisible, setPopoverVisible] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [tableData, setTableData] = useState([]);

    const {TextArea} = Input;
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const token = urlParams.get('token');
        if (token) {
            localStorage.setItem('authToken', token);
        }
    }, []);

    useEffect(() => {
        if (status || error) {  // Check if there's a success or failure message
            const timer = setTimeout(() => {
                setPopoverVisible(false);  // Hide the popover after 2 seconds
                setStatus('Processing')
            }, 2000);
            return () => clearTimeout(timer);  // Cleanup the timer on component unmount
        }
    }, [status, error]);  // Listen for changes to status and error states

    useEffect(() => {
        if (selectedClient) {

            fetchProspectNames();
        }
    }, [selectedClient]);


    // Setup axios to use the token for subsequent API calls
    useEffect(() => {
        const storedToken = localStorage.getItem('authToken');
        if (storedToken) {
            axios.defaults.headers.common['Authorization'] = `Bearer ${storedToken}`;
        }
    }, []);
    useEffect(() => {
        // 2. Fetch the client names from the API within the useEffect hook
        const fetchClientNames = async () => {
            try {
                console.log("Fetching client names...");
                const response = await axios.get(`${apiBaseUrl}/api/organizations/`, {
                    params: {orgtype: "client"}
                });
                if (response.data) {
                    setClientNames(response.data);
                }
            } catch (error) {
                console.error("Error fetching client names:", error);
            }
        };

        fetchClientNames();
    }, []);


    useEffect(() => {
        // Function to fetch model names
        const fetchModelNames = async () => {
            try {
                console.log("Fetching model names...");
                const response = await axios.get(`${apiBaseUrl}/api/models/`);
                if (response.data) {
                    // Set the model names
                    setModelNames(response.data);

                    // Find the default model and update the selectedModel state
                    const defaultModel = response.data.find(model => model.is_default);
                    if (defaultModel) {
                        setSelectedModel(defaultModel.name);
                    }
                }
            } catch (error) {
                console.error("Error fetching model names:", error);
            }
        };

        // Call the fetch function
        fetchModelNames();
    }, []);
    const fetchInterviewData = async (interviewId) => {
        try {
            const response = await axios.get(`${apiBaseUrl}/api/interview_data/${interviewId}`);
            if (response.data) {
                const formattedData = response.data.map((item, index) => ({
                    key: index,
                    field_name: item.field_name, // Ensure this matches the API response structure
                    // Add other fields as needed
                }));
                setTableData(formattedData);
            }
        } catch (error) {
            console.error("Error fetching interview data:", error);
        }
    };

    const columns = [
        {
            title: 'Fields Processed',
            dataIndex: 'field_name',
            key: 'field_name',
        },
        // Add more columns as needed
    ];

    const fetchProspectNames = async () => {
        setSelectedProspect('');
        try {
            console.log("Fetching client names...");
            const response = await axios.get(`${apiBaseUrl}/api/organizations/children/${selectedClient}`);
            if (response.data) {
                setProspectNames(response.data);
            }
        } catch (error) {
            console.error("Error fetching prospect names:", error);
        }
    };

    const handleCheckRecent = () => {
        handleRefreshInterviewList();
        setIsModalVisible(true);
    };

    const clearModal = () => {
        setIsModalVisible(false);
        setSelectedInterview('');
        setTableData([]);
    };

    const handleRefreshInterviewList = async () => {
        try {
            console.log("Fetching recent interviews...");
            const response = await axios.get(`${apiBaseUrl}/api/participants/?org_id=&expanded=true`, {
                params: {limit: 10}
            });
            if (response.data) {
                setInterviewNames(response.data);
            }
        } catch (error) {
            console.error("Error fetching client names:", error);
        }
    };

    const handleRefresh = () => {
        fetchInterviewData(selectedInterview)
    };


    const handleFormSubmit = async (e) => {
        e.preventDefault();
        setPopoverVisible(true);
        // Check for missing required fields
        if (!transcript || !interviewee || !selectedClient || !selectedProspect) {
            setError('Please fill in all required fields.');
            return;
        }

        try {
            // Prepare the data to send in the POST request
            const postData = {
                interviewDate: interviewDate,
                transcript: transcript,
                keyFocusArea: keyFocusArea,
                interviewee: interviewee,
                selectedClient: selectedClient,
                selectedProspect: selectedProspect,
                selectedModel: selectedModel
            };

            // Make a POST request to /interviews
            const response = await axios.post(`${apiBaseUrl}/api/interviews/`, postData);

            if (response.status === 200) {
                popoverContent = <div style={{color: 'green', backgroundColor: 'white'}}>{status}</div>;
                setStatus('Interview Successfully Submitted!');
                // You can reset form fields or perform other actions upon successful submission
                setTranscript('');
                setKeyFocusArea('');
                setInterviewee('');
                setSelectedClient('');
                setSelectedProspect('');
                setError('');
            }
        } catch (error) {
            popoverContent = <div style={{color: 'red', backgroundColor: 'white'}}>{status}</div>;
            setStatus('Interview Submission Failed. Please try again.');
            console.error('Error submitting form:', error);
        }
    };

    let popoverContent;
    if (error) {
        popoverContent = <div style={{color: 'red', backgroundColor: 'white'}}>{status}</div>;
    } else if (status) {
        popoverContent = <div style={{color: 'green', backgroundColor: 'white'}}>{status}</div>;
    } else {
        // Default
        popoverContent = "Processing";
    }

    // ... (rest of your imports and code)

    return (
        <>
            <div>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <Header/>
                <NavBar email={userEmail} activeMenu="input"/>


                <Row className="textAreaContainer">

                    {/* Left column */}
                    <Col span={3}></Col>
                    <Col span={14}>
                        <div id="page_input_transcript">

                            <h2>Input Transcription</h2>
                        </div>
                        <TextArea
                            placeholder="Paste Transcript"
                            id="transcript_input"
                            value={transcript}
                            onChange={(e) => setTranscript(e.target.value)}
                            rows={20}

                        />
                        <TextArea
                            style={{marginTop: '20px'}}
                            placeholder="Input Key Focus Area (optional) - For Summary Only"
                            id="focal_input"
                            value={keyFocusArea}
                            onChange={(e) => setKeyFocusArea(e.target.value)}
                        />
                    </Col>
                    <Col span={1}></Col>
                    {/* Right column */}
                    <Col span={5}>

                        <form className="inputForm" onSubmit={handleFormSubmit}>
                            <Row>
                                <Select
                                    style={{marginBottom: '5px', marginRight: '10px', marginTop: '60px', width: '40%'}}
                                    value={selectedClient}
                                    onChange={(value) => setSelectedClient(value)}
                                >
                                    <option value="" disabled hidden>Select Client Name</option>
                                    {
                                        clientNames.map(client => (
                                            <option key={client.id} value={client.id}>
                                                {client.name}
                                            </option>
                                        ))
                                    }
                                </Select> </Row>
                            <Row>
                                <Select
                                    style={{marginBottom: '10px', width: '40%'}}
                                    value={selectedProspect}
                                    onChange={(value) => setSelectedProspect(value)}
                                >
                                    <option value="" disabled hidden>Select Prospect Org</option>
                                    {
                                        prospectNames.map(prospect => (
                                            <option key={prospect.id} value={prospect.id}>
                                                {prospect.name}
                                            </option>
                                        ))
                                    }
                                </Select>

                            </Row>
                            <Row>
                                <DatePicker
                                    style={{marginBottom: '10px'}}
                                    id="input_date_interview"
                                    onChange={(date, dateString) => setInterviewDate(dateString)}
                                />
                            </Row>
                            <Row>
                                <Input
                                    style={{marginBottom: '5px', width: '40%'}}
                                    type="text"
                                    placeholder="Interviewee"
                                    value={interviewee}
                                    onChange={(e) => setInterviewee(e.target.value)}
                                />
                            </Row>
                            <Row>
                                <Select
                                    style={{marginBottom: '25px', marginRight: '10px', marginTop: '5px', width: '40%'}}
                                    value={selectedModel}
                                    onChange={(value) => setSelectedModel(value)}
                                >
                                    <option value="" disabled hidden>Select Model Name</option>
                                    {
                                        modelNames.map(model => (
                                            <option key={model.label} value={model.name}>
                                                {model.label}
                                            </option>
                                        ))
                                    }
                                </Select>
                            </Row>
                            <Row>
                                <Popover
                                    style={{marginTop: '25px'}}
                                    content={popoverContent}
                                    visible={popoverVisible}
                                    trigger="click"
                                    placement="bottomRight"
                                    onVisibleChange={(visible) => setPopoverVisible(visible)}
                                >
                                    <Button type="primary" onClick={handleFormSubmit}
                                            id="input_submit_button">Submit</Button>
                                </Popover>
                            </Row>
                        </form>
                        <Row>
                            <Button type="default" onClick={handleCheckRecent} id="check_recent_button"
                                    style={{background: 'grey', marginTop: '25px'}}>Check Recent Inputs</Button>
                        </Row>
                    </Col>
                    <Col span={1}></Col>
                </Row>\

            </div>
            <Modal
                title="Recent Inputs - Select Interview to see status"
                visible={isModalVisible}

                onCancel={() => {
                    clearModal();
                }}

                footer={[
                    <Button key="submit" type="primary" onClick={() => clearModal()}>
                        OK
                    </Button>
                ]}
            >
                <Select
                    style={{marginBottom: '5px', marginRight: '10px', width: '40%'}}
                    value={selectedInterview}
                    onChange={(value) => {
                        setSelectedInterview(value);
                        fetchInterviewData(value);
                    }}
                >
                    <option value="" disabled hidden>Select Interview</option>
                    {
                        interviewNames.map(interview => (
                            <option key={interview.id} value={interview.id}>
                                {interview.name}
                            </option>
                        ))
                    }
                </Select>
                <Table dataSource={tableData} columns={columns}/>
                <Button type="primary" onClick={handleRefresh}
                        id="modal_refresh" style={{marginTop: '10px'}}>Refresh</Button>
                <p>If the interview is not in the dropdown, please resubmit</p>
                {/* Add more content as needed */}
            </Modal>


        </>
    );
}

export default InputPage;
import React from 'react';
import logo_top_left from "../../assets/logo3.png"; // Updated logo import
import './_style.css'; // Importing the _style.css file


function Header({email, activeMenu}) {
  return (
    <a href={"/input"}>
      <div id="sign_in_header">
        <img alt="sign_in_header" src={logo_top_left} id="img_logo_top_left"/>
      </div>
    </a>
  );
}

export default Header;

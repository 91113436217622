import React from "react";
import {useRoutes} from "react-router-dom";
import SignIn from "./pages/sign_in";
import Input from "./pages/input";
import ReportSubmission from "./pages/reports_submission";
import ConfigEditFields from "./pages/config_edit_fields";
import ConfigEditClient from "./pages/config_edit_clients"
import SelectFieldsComponent from "./pages/config_select_fields";
import ReportWeeklyRecap from "./pages/reports_weekly_recap";
import ConfigDeleteInterviews from "./pages/config_delete_interviews";
import ConfigAssignProspects from "./pages/config_assign_prospects";


function Routes() {
  return useRoutes([
    {path: "/", element: <SignIn/>},
    {path: "/input", element: <Input/>},
    {path: "/reports_submission", element: <ReportSubmission/>},
    {path: "/reports_weekly_recap", element: <ReportWeeklyRecap/>},
    {path: "/config_edit_clients", element: <ConfigEditClient/>},
    {path: "/config_edit_fields", element: <ConfigEditFields/>},
      {path: "/config_delete_interviews", element: <ConfigDeleteInterviews/>},
      {path: "/config_assign_prospects", element: <ConfigAssignProspects/>},
    {path: "/config_select_fields", element: <SelectFieldsComponent/>}]);
}

export default Routes;
import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import '../../style.css'
import google_login_icon from "../../assets/google_login.png";
import icon_pastsight_white from "../../assets/icon_pastsight_white.png";
import logo_top_left from "../../assets/logo3.png";


function SignIn({email, activeMenu}) {
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const apiBaseUrlHC  = "https://pastsighttra-backend-main-7dd6e8-xeyz5qttpa-ue.a.run.app"
  const navigate = useNavigate();

  useEffect(() => {
    // Check if there's a token in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');

    if (token) {
      // Store the token in local storage
      localStorage.setItem('authToken', token);

      // Redirect to Reports_Submission
      navigate('/input');
    }
  }, []);


  return (
    <>
      <div id="sign_in_header">
        <img src={logo_top_left} id="img_logo_top_left"/>
      </div>

      <div className="div-body-encapsulate">
        <div className="div-content-login">

          <img src={icon_pastsight_white} alt="Sign in User" id="icon-sign-in-user"/>

          <br/><br/>
          <div id="label_welcome_back">Welcome Back</div>
          <br/>

          <br/><br/>
          <a id="button_login_google" href={`${apiBaseUrlHC}/oauth/google`}>
            <img src={google_login_icon} alt="Google Logo" id="google-logo"/>
            <div className="default_input_box" id="button_login_google"><span id="text_inner_button_login_google">Sign in with Google</span>
            </div>
          </a>
          <br/>
        </div>
      </div>
    </>
  );
}

export default SignIn;

import React, {useEffect, useState} from 'react';
import './_style.css';
import {getEmail} from "../../api";
import Header from "../../components/header/index.js";
import NavBar from "../../components/navbar/index.js";
import axios from "axios";
import {Button, DatePicker, Select,Col,Row} from 'antd'; // Import Ant Design button

function ReportWeeklyRecap({email, activeMenu}) {
    const [userEmail, setUserEmail] = useState('');
    const [status, setStatus] = useState('');
    const [selectedClient, setSelectedClient] = useState('');
    const [selectedProspect, setSelectedProspect] = useState('');
    const [clientNames, setClientNames] = useState([]);
    const [prospectNames, setProspectNames] = useState([]);
    const [recapReport, setRecapReport] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const copyToClipboard = () => {
        navigator.clipboard.writeText(recapReport)
            .then(() => {
                console.log('Text copied to clipboard');
            })
            .catch(err => {
                console.error('Unable to copy text: ', err);
            });
    };
    const fetchRecapReport = async () => {
        try {
            const response = await axios.get(`${apiBaseUrl}/api/recap_report/`);
            if (response.data) {
                setRecapReport(response.data); // Assume the response contains plain text for simplicity
            }
        } catch (error) {
            console.error("Error fetching recap report:", error);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        const payload = {
            client: selectedClient,
            prospect: selectedProspect,
            start_date: startDate,
            end_date: endDate
        };

        try {
            const response = await axios.post(`${apiBaseUrl}/api/recap_report/`, payload);
            console.log('Response:', response.data);
            setRecapReport(response.data);
        } catch (error) {
            console.error("Error submitting data:", error);
        }
    };

    useEffect(() => {
        const fetchEmail = async () => {
            const email = await getEmail();
            console.log("Fetched email:", email);
            if (email) {
                setUserEmail(email);
                console.log("Set userEmail state:", userEmail);
            }
        };
        fetchEmail();
    }, []);
    useEffect(() => {
        // 2. Fetch the client names from the API within the useEffect hook
        const fetchClientNames = async () => {
            try {
                console.log("Fetching client names...");
                const response = await axios.get(`${apiBaseUrl}/api/organizations/`, {
                    params: {orgtype: "client"}
                });
                if (response.data) {
                    setClientNames(response.data);
                }
            } catch (error) {
                console.error("Error fetching client names:", error);
            }
        };

        fetchClientNames();
    }, []);

    useEffect(() => {
        if (selectedClient) {
            fetchProspectNames();
        }
    }, [selectedClient]);
    const fetchProspectNames = async () => {
        setSelectedProspect('');
        try {
            console.log("Fetching client names...");
            const response = await axios.get(`${apiBaseUrl}/api/organizations/children/${selectedClient}`);
            if (response.data) {
                setProspectNames(response.data);
            }
        } catch (error) {
            console.error("Error fetching prospect names:", error);
        }
    };
    return (
        <>
            <div>
                <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
                <Header/>
                <NavBar email={userEmail} activeMenu="input"/>
                <Row align={'center'}><h2>Report - Weekly Recap</h2></Row>
                <div className="container_input_page">


                    <Col span={1}></Col>
                    <div className="left-div_input_page">
                        <div className="inner-div-top_input_page">

                            <form id="page_report_submission" onSubmit={handleSubmit}>
                                <Col style={{alignItems: "left", marginTop: "25px", padding: "10px"}}>
                                    <h3>Choose Client and Dates</h3>

                                <Select
                                    style={{ width: '100%' }}
                                    id="form-element_input_page_report_client"
                                    value={selectedClient} // Bind the selected value to the state variable
                                    onChange={(value) => setSelectedClient(value)} // Update the state variable on selection change
                                >
                                    <option value="" disabled hidden>Select Client Name</option>
                                    {clientNames.map(client => (
                                        <option key={client.id} value={client.id}>
                                            {client.name}
                                        </option>
                                    ))}
                                </Select>
                                <br></br>
                                <Select
                                    style={{ width: '100%' }}
                                    id="form-element_input_page_report_prospect"
                                    value={selectedProspect} // Bind the selected value to the state variable
                                    onChange={(value) => setSelectedProspect(value)} // Update the state variable on selection change
                                >
                                    <option value="" disabled hidden>Select Prospect Org</option>
                                    {prospectNames.map(prospect => (
                                        <option key={prospect.id} value={prospect.id}>
                                            {prospect.name}
                                        </option>
                                    ))}
                                </Select>
                                <br/>     <br/>
                                <p className="page_reports_p">Start Date</p>
                                <DatePicker
                                    style={{ width: '100%' }}
                                    className="form-element_input_page"
                                    id="input_date_1"
                                    onChange={(date, dateString) => setStartDate(dateString)} // Step 2: Set up onChange handler for the DatePicker
                                />
                                <p id="page_reports_end_p">End Date</p>
                                <DatePicker
                                    style={{ width: '100%' }}
                                    className="form-element_input_page"
                                    id="input_date_2"
                                    onChange={(date, dateString) => setEndDate(dateString)} // Step 2: Set up onChange handler for the DatePicker
                                />

                                <Button
                                    style={{marginTop: '20px'}}
                                    type="primary"

                                    id="form-element_input_page_button"
                                    onClick={handleSubmit}
                                >
                                    Submit
                                </Button>
                                    </Col>
                            </form>
                        </div>
                    </div>
                    <div className="right-div_input_page">
                        <div className="right-div_input_page">
                <textarea
                    id="recap_report_textarea"
                    placeholder=""
                    className="custom-textarea"
                    value={recapReport} // Bind value to the state
                    readOnly // Make textarea read-only so fetched data isn't accidentally edited
                ></textarea>
                            <Button className="download-button" onClick={copyToClipboard}>Copy</Button>
                            <Button onClick={fetchRecapReport}>See Latest Recap
                                Report</Button> {/* AntD Button to trigger fetching */}
                        </div>
                        {/* ... (rest of your JSX) */}


                    </div>
                </div>
            </div>
        </>
    );
}

export default ReportWeeklyRecap;

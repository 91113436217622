// CheckBoxes.js
import React, {useState, useEffect} from 'react';
import axios from 'axios';
import {Tooltip} from 'antd';


function CheckBoxes({selectedClient, setCheckboxStates}) {
    const [runFieldsClient, setRunFieldsClient] = useState([]);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const toTitleCase = (str) => {
        return str.replace(/\w\S*/g, function (txt) {
            return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
        });
    };

    useEffect(() => {
        if (selectedClient) {
            const fetchRunFieldsClient = async () => {
                try {
                    const url = `${apiBaseUrl}/api/client_run_settings/${selectedClient}`;
                    const response = await axios.get(url);

                    if (response.data) {
                        setRunFieldsClient(response.data);
                    }
                } catch (error) {
                    console.error("Error fetching run fields:", error);
                }
            };

            fetchRunFieldsClient();
        } else {
            setRunFieldsClient([]);
        }
    }, [selectedClient, setCheckboxStates]);

    function handleCheckboxChange(field, checked) {
        console.log("Checkbox changed for:", field.field_name, "New Value:", checked);

        // Update the runFieldsClient
        const updatedFields = runFieldsClient.map(item => {
            if (item.interview_field_id === field.interview_field_id) {
                return {
                    ...item,
                    client_config_id: item.client_config_id ? null : 'checked'
                };
            }
            return item;
        });
        setRunFieldsClient(updatedFields);

        // Update the checkboxStates
        setCheckboxStates(prevState => ({
            ...prevState,
            [field.interview_field_id]: checked
        }));
    }

    return (
        <div>
            {
                runFieldsClient
                    .sort((a, b) => a.is_generic === b.is_generic ? 0 : a.is_generic ? -1 : 1)
                    .map((field, index) => (
                        <label
                            key={index}
                            style={{
                                display: 'block',
                                color: field.is_generic === false ? 'yellow' : 'inherit'
                            }}
                        >
                            <input
                                type="checkbox"
                                checked={field.client_config_id !== null}
                                onChange={e => handleCheckboxChange(field, e.target.checked)}
                            />
                            <Tooltip
                                title={`${field.field_prompt}`}
                                placement="rightTop"
                                overlayStyle={{ color: 'white'}}
                            >
                                {toTitleCase(field.field_name)}
                            </Tooltip>

                        </label>
                    ))
            }
        </div>
    );

}

export default CheckBoxes;

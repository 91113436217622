import React, {useState} from 'react';
import {LogoutOutlined, PlusSquareOutlined, SettingOutlined, TableOutlined} from '@ant-design/icons';
import {Menu, Modal} from 'antd';
import {useLocation, useNavigate} from 'react-router-dom';
import './_style.css';
import axios from "axios";

const NavBar = ({email}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [current, setCurrent] = useState(getKeyFromPathname(location.pathname));
  const [isModalVisible, setIsModalVisible] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

  function getKeyFromPathname(pathname) {
    switch (pathname) {
      case '/input':
        return 'input';
      case '/reports_submission':
        return 'reports_submission';
      case '/reports_weekly_recap':
        return 'reports_weekly_recap';
      case '/config_edit_clients':
        return 'config_edit_clients';
      case '/config_edit_fields':
        return 'config_edit_fields';
      case '/config_select_fields':
        return 'config_select_fields';
              case '/config_delete_interviews':
        return 'config_delete_interviews';
      default:
        return '';
    }
  }

const handleOk = async () => {
    setIsModalVisible(false);

    try {
        // Make a request to clear the user's session
        await axios.get(`${apiBaseUrl}/api/clearsession/`); // Adjust the method and endpoint as needed
        localStorage.removeItem('authToken'); // If you used localStorage

        // Once the session is cleared, navigate to the sign-in page
        navigate('/');
    } catch (error) {
        console.error('Failed to clear session:', error);
        navigate('/');
        // Handle any errors, like showing a notification to the user
    }
};

  const onClick = (e) => {
    console.log('click ', e);
    if (e.key !== 'logout') {
      setCurrent(e.key);
      navigate(getPathFromKey(e.key));
    } else {
      e.domEvent.stopPropagation(); // Prevent the event from propagating to the Menu component
    }
  };

  function getPathFromKey(key) {
    switch (key) {
      case 'input':
        return '/input';
      case 'reports_submission':
        return '/reports_submission';
      case 'reports_weekly_recap':
        return '/reports_weekly_recap';
      case 'config_edit_fields':
        return '/config_edit_fields';
      case 'config_edit_clients':
        return '/config_edit_clients';
      case 'config_select_fields':
        return '/config_select_fields';
        case 'config_delete_interviews':
        return '/config_delete_interviews';
        case 'config_assign_prospects':
        return '/config_assign_prospects';
      default:
        return '/';
    }
  }

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Menu
        className="menu_class_horizontal"
        onClick={onClick}
        selectedKeys={current === 'logout' ? [] : [current]}
        mode="horizontal"
      >

        <Menu.Item key="input" icon={<PlusSquareOutlined/>} title="Input" style={{textAlign: 'left'}}>
          Input
        </Menu.Item>

        <Menu.SubMenu key="reports" icon={<TableOutlined/>} title="Reports" style={{textAlign: 'left'}}>
          <Menu.Item key="reports_submission" style={{textAlign: 'left'}}>
            Report Submission
          </Menu.Item>

          <Menu.Item key="reports_weekly_recap" style={{textAlign: 'left'}}>
            Report Weekly Recap
          </Menu.Item>
        </Menu.SubMenu>


        <Menu.SubMenu key="config" icon={<SettingOutlined/>} title="Config" style={{textAlign: 'left'}}>
          <Menu.Item key="config_edit_clients" style={{textAlign: 'left'}}>
            Create Organizations
          </Menu.Item>


          <Menu.Item key="config_assign_prospects" style={{textAlign: 'left'}}>
            Assign Organization Prospects
          </Menu.Item>

          <Menu.Item key="config_edit_fields" style={{textAlign: 'left'}}>
            Submission Report - Edit Fields
          </Menu.Item>

          <Menu.Item key="config_select_fields" style={{textAlign: 'left'}}>
            Submission Report - Select Fields
          </Menu.Item>

          <Menu.Item key="config_delete_interviews" style={{textAlign: 'left'}}>
            Delete Interviews
          </Menu.Item>
        </Menu.SubMenu>

        <Menu.Item
          key="logout"
          icon={<LogoutOutlined/>}
          onClick={showModal}
          style={{position: 'absolute', right: '10px'}}
          className="logout-button"
        >
          Logout
        </Menu.Item>
      </Menu>

      <Modal
        title="Logout Confirmation"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        centered
      >
        <p>Are you sure you want to logout?</p>
      </Modal>
    </>
  );
};

export default NavBar;
